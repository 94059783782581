<template>
  <div
    class="w-full bg-white shadow-lg rounded-lg relative my-4"
    @click="handleRedirect"
  >
    <img class="w-full h-60 rounded-t-lg object-fill object-center"
         :src="fellowship.imageUrl"
         alt="kebaktian">
    <div class="flex flex-col p-4 w-full">
      <div class="text-sm text-gray-400 text-right">{{ friendlyDate }}</div>
      <div class="text-lg font-bold text-gray-800 w-5/6">{{ fellowship.title }}</div>
      <div class="text-base text-gray-400 w-5/6">{{ fellowship.summary }}</div>
    </div>
  </div>
</template>

<script>
import { dateFormater } from '@/utils/time'
export default {
  name: 'FellowShipCard',
  props: {
    fellowship: {
      type: Object,
      required: true
    }
  },
  computed: {
    friendlyDate () {
      return dateFormater(this.fellowship.date)
    }
  },
  methods: {
    handleRedirect () {
      this.$router.push({
        name: 'DetailFellowship',
        params: {
          id: this.fellowship.id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
